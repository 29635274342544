









































































































































import {
  ReactionStatOrientationEnum,
  ReactionStatRecordMetricsFields
} from '@/includes/types/statistics/reactions';
import TableSearchNew from "@/components/statistic/components/TableSearch.vue";
import SetPeriod from "@/views/chat/statistics/components/mainStatistics/setPeriod.vue";
import { ReactionStatistics } from '@/includes/logic/statistics/reactions/ReactionStatistics'
import UserReactionChart from "@/components/statistic/reactions/UserReactionChartModal.vue";
import { StatisticPeriodEnum } from "@/includes/types/statistics/statistics.types";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import TableSearch from '@/components/statistic/components/TableSearch.vue';

import PiTabs from 'piramis-base-components/src/components/PiTabs.vue';
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue';
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue';
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue';

import VueApexCharts from "vue-apexcharts";
import { Component, Mixins, Watch } from 'vue-property-decorator';

@Component({
  components: {
    NotAvailableOptionsOverlay,
    PageTitle,
    UserReactionChart,
    TableSearchNew,
    SetPeriod,
    VueApexCharts,
    PiTabs,
    SelectInput,
    ConfigField,
    TableSearch
  }
})
export default class StatisticsReactions extends Mixins(ModelSetter, TariffsTagsHelper) {
  reactionStatistics: ReactionStatistics | null = null;

  currentField: ReactionStatRecordMetricsFields = 'reaction_count'

  isLoading = false

  @Watch('$route.params.CHAT_ID')
  chatChanged() {
    this.reactionStatistics = new ReactionStatistics()

    this.reactionStatistics.getTotalStatistics()
  }

  changeTab(tab: ReactionStatRecordMetricsFields) {
    this.isLoading = true

    setTimeout(() => {
      this.currentField = tab

      this.isLoading = false
    }, 0)
  }

  get periodGroupOptions():Array<SelectOptionData> {
    return [
      {
        label: this.$t(`statistics_group_day`).toString(),
        value: StatisticPeriodEnum.Day
      },
      {
        label: this.$t(`statistics_group_week`).toString(),
        value: StatisticPeriodEnum.Week
      },
      {
        label: this.$t(`statistics_group_month`).toString(),
        value: StatisticPeriodEnum.Month
      },
      {
        label: this.$t(`statistics_group_year`).toString(),
        value: StatisticPeriodEnum.Year
      },
    ]
  }

  get orientationOptions():Array<SelectOptionData> {
    return [
      {
        label: this.$t(`statistic_reactions_orientation_actor`).toString(),
        value: ReactionStatOrientationEnum.Actor
      },
      {
        label: this.$t(`statistic_reactions_orientation_target`).toString(),
        value: ReactionStatOrientationEnum.Target
      },
    ]
  }

  get totalChartTabs(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('statistic_reactions_tab_reaction_count').toString(),
        value: 'reaction_count',
        icon: {
          name: 'emoji_emotions'
        }
      },
      {
        label: this.$t('statistic_reactions_tab_user_count').toString(),
        value: 'user_count',
        icon: {
          name: 'people'
        }
      },
    ]
  }

  created() {
    if (!this.advancedLicenseTag) {
      this.reactionStatistics = new ReactionStatistics()

      this.reactionStatistics.getTotalStatistics()
    }
  }
}
