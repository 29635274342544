export type KeysOfType<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never;
}[keyof T]

export type ClassProperties<C> = {
  [P in keyof C as C[P] extends Function ? never : P]: C[P]
}

export function constArrayIncludes<T>(values: readonly T[], x: any): x is T {
  return values.includes(x);
}

export function isEnumValue<E extends string | number>(obj: Record<string, E>, value: string | number): value is E {
  const enumValues = Object.values(obj) as Array<string | number>;

  return enumValues.includes(value)
}
