import StatisticsApi from "@/includes/Api/Statistics.api"
import {
  ReactionStatEmojiRecord,
  ReactionStatModeEnum,
  ReactionStatOrientationEnum,
  ReactionStatPagination,
} from "@/includes/types/statistics/reactions"
import { errorNotification } from "@/includes/NotificationService";
import { StatisticPeriodEnum, StatisticPeriodUserFilter } from "@/includes/types/statistics/statistics.types";

export class GetReactionStatisticsByPeriodReq {
  constructor(
      public chat_id: number,
      public from: string,
      public to: string,
      public mode: ReactionStatModeEnum,
      public period: StatisticPeriodEnum,
      public orientation: ReactionStatOrientationEnum,
      public users?: StatisticPeriodUserFilter,
      public pagination?: ReactionStatPagination,
      public emoji?: Pick<ReactionStatEmojiRecord, 'value' | 'id'>,
  ) {
  }

  getReactionStatisticsByPeriod<T>() {
    return StatisticsApi.getReactionStatisticsByPeriod<T>({
      chat_id: this.chat_id,
      from: this.from,
      to: this.to,
      mode: this.mode,
      period: this.period,
      orientation: this.orientation,
      users: this.users,
      pagination: this.pagination,
      reaction_id: this.emoji?.id
    })
        .then(({ data }) => data)
        .catch(errorNotification)
  }
}
