import { BaseUserInfo } from "@/includes/types/UserProfile/types";
import { StatisticPeriodEnum, StatisticPeriodUserFilter } from "@/includes/types/statistics/statistics.types";

import { ApexChartSeries } from "piramis-base-components/src/shared/types/ApexChartSeries.types";

import { ApexOptions } from "apexcharts";

export enum ReactionStatModeEnum {
  Total = "Total",
  Users = "Users",
  Partition = "Partition"
}

export type ReactionStatPagination = {
  direction: 'Asc' | 'Desc'
  limit?: number
  offset?: number
}

export const enum ReactionStatOrientationEnum {
  Actor = 'Actor',
  Target = 'Target'
}

export type GetReactionStatisticsByPeriodReq = {
  chat_id: number
  from: string
  to: string
  mode: ReactionStatModeEnum
  period: StatisticPeriodEnum
  orientation: ReactionStatOrientationEnum
  users?: StatisticPeriodUserFilter
  pagination?: ReactionStatPagination
  reaction_id?: number
}

export enum ReactionMood {
  None = "None",
  Positive = "Positive",
  Negative = "Negative",
}

export type ReactionStatEmojiRecord = Omit<ReactionStatTotalRecord, 'mood'>

export function isReactionEmojiRecordData(value: unknown): value is Pick<ReactionStatEmojiRecord, 'value' | 'id'>  {
  if (value && typeof value === 'object') {
    return 'id' in value && 'value' in value
  }

  return false
}

export type ReactionStatRecordMetrics = {
  reaction_count: number
  user_count: number
}

export type ReactionStatTotalRecord = {
  mood: ReactionMood
  value: string
  id: number
} & ReactionStatRecordMetrics

export type ReactionStatTotalRes = {
  reactions: Array<ReactionStatTotalRecord>
  periods: Record<string, Record<ReactionMood, ReactionStatRecordMetrics>>
}

export type UserReactionRecord = BaseUserInfo & {
  data: Array<Omit<ReactionStatTotalRecord, 'reaction_count' | 'user_count'> & {count: number}>
} & Partial<Pick<ReactionStatRecordMetrics, 'reaction_count'>>

export type UserStatRes = {
  count: number
  data: Array<UserReactionRecord>
}

export type GetReactionStatisticsByPeriodRes<T> = T extends ReactionStatModeEnum.Total
    ? ReactionStatTotalRes
    : T extends ReactionStatModeEnum.Users
        ? UserStatRes
        : T extends ReactionStatModeEnum.Partition
            ? Omit<UserStatRes, 'count'> : unknown

export type ReactionStatRecordMetricsFields = keyof ReactionStatRecordMetrics

export type EmojiChartMetricData = {
  chart: { options: ApexOptions, series: ApexChartSeries }
}
