import {
  EmojiChartMetricData,
  ReactionMood, ReactionStatEmojiRecord,
  ReactionStatModeEnum,
  ReactionStatOrientationEnum,
  ReactionStatPagination, ReactionStatRecordMetricsFields,
  UserStatRes
} from "@/includes/types/statistics/reactions";
import { GetReactionStatisticsByPeriodReq } from "@/includes/logic/statistics/reactions/GetReactionStatisticsByPeriodReq";
import { ColorMapper, getEmojiChartApexOptions } from "@/includes/logic/statistics/reactions/util";
import { BaseUserInfo } from "@/includes/types/UserProfile/types";
import { StatisticPeriodEnum, StatisticPeriodUserFilter } from "@/includes/types/statistics/statistics.types";
import { isEnumValue } from "@/includes/types/util.types";

type ReactionUserStatRecord =
    { type: "Chart", user: BaseUserInfo } & EmojiChartMetricData
    | { type: "Total", user: BaseUserInfo } & Partial<Record<ReactionStatRecordMetricsFields, {
  value: string,
  total: number
}>>

export class UsersReactionStatistics extends GetReactionStatisticsByPeriodReq {

  limit = 10

  offset = 0

  page = 1

  isModalOpen = false

  count = 0

  isUsersLoading = false

  statistics: Array<ReactionUserStatRecord> | null = null

  constructor(
      chat_id: number,
      from: string,
      to: string,
      period: StatisticPeriodEnum,
      orientation: ReactionStatOrientationEnum,
      users?: StatisticPeriodUserFilter,
      pagination?: ReactionStatPagination,
      emoji?: Pick<ReactionStatEmojiRecord, 'value' | 'id'>
  ) {
    super(chat_id, from, to, ReactionStatModeEnum.Users, period, orientation, users, pagination, emoji);
  }

  getPeriodReactionsChart(data: UserStatRes['data'][0]['data']) {
    const reactionInfos: Array<Pick<ReactionStatEmojiRecord, 'reaction_count' | 'value'> & { color: string }> = (data ?? [])
        .map(r => {
          return {
                  value: r.value,
                  reaction_count: r.count,
                  color: ColorMapper[r.mood]
          }
        })

    const colors = reactionInfos.map(r => r.color)
    const seriesData = reactionInfos.map(r => r.reaction_count)
    const categories = reactionInfos.map(r => r.value)

    return {
      options: getEmojiChartApexOptions({
        fill: { colors },
        xaxis: {
          categories
        },
      }),
      series: [ {
        name: "",
        data: seriesData
      } ]
    }
  }

  getUsersWithChartData(users: UserStatRes['data']): Array<ReactionUserStatRecord> {
    return users.map(u => {
      const { data, reaction_count, ...user } = u

      if (data) {
        return {
          type: "Chart",
          user,
          chart: this.getPeriodReactionsChart(data)
        }
      } else {
        return {
          type: "Total",
          user,
          ...reaction_count ? {
            'reaction_count': {
              value: this.emoji?.value ?? '',
              total: reaction_count ?? 0
            }
          } : {},
        }
      }
    })
  }

  getPartitionRequest() {
    this.pagination = {
      direction: 'Desc',
      limit: this.limit,
      offset: this.page === 1 ? 0 : (this.page - 1) * this.limit
    }

    this.isUsersLoading = true

    this.getReactionStatisticsByPeriod<ReactionStatModeEnum.Partition>()
        .then(res => {
          if (res) {
            this.statistics = this.getUsersWithChartData(res.data)
          }

          this.isUsersLoading = false
        })
  }

  getUsers() {
    this.isUsersLoading = true

    this.getReactionStatisticsByPeriod<ReactionStatModeEnum.Users>()
        .then(res => {
          if (res) {
            this.count = res.count

            this.statistics = this.getUsersWithChartData(res.data)
          }

          this.isUsersLoading = false
        })
  }

  paginationChange(pagination: number, size: number) {
    this.page = pagination

    if (this.limit !== size) {
      this.page = 1
    }

    this.limit = size
  }
}
