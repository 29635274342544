










































































import { StatisticPeriodUserFilter, StatisticPeriodUserFilterMode } from "@/includes/types/statistics/statistics.types";

import { GetUserInfoRes } from "piramis-base-components/src/shared/logic/getUsersInfo/types";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import { atSignedLogin } from "piramis-base-components/src/shared/utils/tgLoginHelpers";
import SearchTextInput from 'piramis-base-components/src/components/Pi/fields/SearchTextInput/SearchTextInput.vue';
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import { trimAndLow } from "piramis-base-components/src/shared/utils/trimAndLow";

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'

@Component({
  methods: {
    atSignedLogin
  },
  components: {
    SearchTextInput
  },
})
export default class TableSearch extends Mixins(ModelSetter) {
  @Prop({ type: Function }) getUsersInfo!: (ids?: Array<string>) => Promise<Array<GetUserInfoRes>>

  @Prop( { type: Object }) users!: StatisticPeriodUserFilter | null

  searchQuery = ''

  userInfos: Array<GetUserInfoRes> = []

  usersLoading = false

  get usersMode() {
    return this.users?.mode ?? null
  }

  get actionButtons(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('show_users_button_title').toString(),
        value: StatisticPeriodUserFilterMode.Include,
        icon: {
          name: 'eye'
        }
      },
      {
        label: this.$t('hide_users_button_title').toString(),
        value: StatisticPeriodUserFilterMode.Exclude,
        icon: {
          name: 'eye-invisible'
        }
      },
      {
        label: this.$t('reset_users_button_title').toString(),
        value: null,
        icon: {
          name: 'close'
        }
      }
    ]
  }

  // @Watch('searchQuery')
  // onSearchQueryChange = debounce((searchString:string) => {
  //   if (!this.userInfos.length) {
  //     if (searchString.length) {
  //       this.onSearchUpdate(searchString)
  //     } else {
  //       this.resetSearch()
  //     }
  //   } else {
  //     if (this.usersMode === null) {
  //       this.resetSearch()
  //     }
  //   }
  // }, 1000)

  @Emit('searchQuery:update')
  onSearchUpdate(query: string):string {
    return query
  }

  @Emit()
  resetSearch():null {
    return null
  }

  @Emit()
  updateUsers(mode: StatisticPeriodUserFilter['mode'], values: StatisticPeriodUserFilter['values']): StatisticPeriodUserFilter {
    return {
      mode,
      values
    }
  }

  changeMode(mode: StatisticPeriodUserFilterMode | null) {
    if (mode !== null) {
      this.updateUsers(mode, this.userInfos.map(u => u.info.id))
    } else {
      this.resetState()
    }
  }

  resetState() {
    this.userInfos = []

    this.resetSearch()
  }

  closeTag(uId: number):void {
    this.userInfos = this.userInfos.filter(n => n.info.id !== uId)

    if (!this.userInfos.length) {
      this.resetSearch()
    } else {
      this.updateByMode()
    }
  }

  updateByMode():void {
    if (this.usersMode) {
      this.updateUsers(this.usersMode, this.userInfos.map(u => u.info.id))
    }
  }

  addSearchQuery():void {
    const formattedQuery = trimAndLow(this.searchQuery)

    if (formattedQuery) {
      this.getUsersInfo([ formattedQuery ])
        .then(res => {
          this.userInfos.push(...res)

          if (this.usersMode) {
            this.updateUsers(this.usersMode, this.userInfos.map(u => u.info.id))
          }
        })
    }
  }

  created() {
    if (typeof this.getUsersInfo === 'function') {
      this.usersLoading = true

      this.getUsersInfo()
        .then((list) => {
          this.userInfos = list
        })
        .finally(() => {
          this.usersLoading = false
        })
    }
  }
}
