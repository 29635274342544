import { ReactionMood } from "@/includes/types/statistics/reactions";

import { ApexOptions } from "apexcharts";
import { merge } from "lodash";

export function getEmojiChartApexOptions(options: Partial<ApexOptions>): ApexOptions {
  return merge({
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      },
      animations: {
        enabled: false
      }
    },
    legend:{
      show: false
    },
    tooltip: {
      y: {
        title: {
          formatter(seriesName: string, opts?: any): string {
            return ''
          }
        }
      }
    },
    grid: {
      show: false,
      padding: {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      labels: {
        style: {
          fontSize: '16px',
        }
      }
    },
    yaxis: {
      show: false
    },
  }, options)
}

export const ColorMapper = {
  [ReactionMood.Positive]: 'rgba(var(--a-success), 1)',
  [ReactionMood.None]: 'rgba(var(--a-primary), 1)',
  [ReactionMood.Negative]: 'rgba(var(--a-danger), 1)',
}
