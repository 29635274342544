










































import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import DatePickerInput from 'piramis-base-components/src/components/Pi/fields/DatePickerInput/DatePickerInput.vue';

import { Component, Emit, Mixins, Prop, VModel } from 'vue-property-decorator'
import moment from "moment";

@Component({
  components: {
    DatePickerInput
  }
})
export default class SetPeriod extends Mixins(ModelSetter) {
  @VModel() period!: { from: string, to: string }

  @Prop({ type: Boolean, default: true, required: false }) searchButton!: boolean

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Emit('searchPeriod')
  searchPeriod() {
  }

  get isRightDate() {
    return (moment(this.period.from).isBefore(this.period.to, "day")) && (!moment(this.period.to).isAfter(moment(), "day"));
  }
}
