import {
  EmojiChartMetricData,
  isReactionEmojiRecordData,
  ReactionMood,
  ReactionStatEmojiRecord,
  ReactionStatModeEnum,
  ReactionStatOrientationEnum,
  ReactionStatRecordMetricsFields,
  ReactionStatTotalRes,
} from "@/includes/types/statistics/reactions";
import { convertLabels } from "@/includes/utils/statistics";
import { GetReactionStatisticsByPeriodReq } from "./GetReactionStatisticsByPeriodReq";
import { UsersReactionStatistics } from "./UsersReactionStatistics";
import i18n from "@/i18n/i18n";
import { ColorMapper, getEmojiChartApexOptions } from "@/includes/logic/statistics/reactions/util";
import { StatisticPeriodEnum, StatisticPeriodUserFilter } from "@/includes/types/statistics/statistics.types";
import { isEnumValue } from "@/includes/types/util.types";

import { FromToTicksReturnType } from "piramis-base-components/src/shared/utils/fromToTicks";
import { ApexOptions } from "apexcharts";

type EmojiCharts = Record<ReactionStatRecordMetricsFields, EmojiChartMetricData | null>

export class TotalReactionStatistics extends GetReactionStatisticsByPeriodReq {
  emojiCharts: EmojiCharts = {
    reaction_count: null,
    user_count: null
  }

  periodChart: Record<ReactionStatRecordMetricsFields, EmojiChartMetricData['chart']> | null = null

  usersReactionStatistics: UsersReactionStatistics | null = null

  isStatisticsLoading = false

  constructor(
      chat_id: number,
      from: string,
      to: string,
      period: StatisticPeriodEnum,
      orientation: ReactionStatOrientationEnum,
      users?: StatisticPeriodUserFilter,
  ) {
    super(chat_id, from, to, ReactionStatModeEnum.Total, period, orientation, users);
  }

  getEmojiTotalCharts(reactions: ReactionStatTotalRes['reactions']) {
    const getChartOptions = (colors: Array<string>, categories: Array<ReactionStatEmojiRecord>): ApexOptions => {
      return getEmojiChartApexOptions({
        fill: { colors },
        yaxis: { show: true },
        xaxis: {
          categories,
          labels: {
            formatter(value: unknown, timestamp?: number, opts?: any): string | string[] {
              if (isReactionEmojiRecordData(value)) {
                return value.value
              } else {
                return ''
              }
            },
          }
        },
        grid: {
          show: true
        },
          chart: {
            events: {
              click: (e: any, chart?: any, options?: {
                config: ApexOptions,
                dataPointIndex: number,
                seriesIndex: number
              }) => {
                if (options) {
                  const { dataPointIndex, seriesIndex, config } = options

                  if (dataPointIndex !== -1 && seriesIndex !== -1) {
                    const emoji = (config.xaxis?.categories ?? [])[dataPointIndex]

                    if (isReactionEmojiRecordData(emoji)) {
                      this.usersReactionStatistics = new UsersReactionStatistics(
                          this.chat_id,
                          this.from,
                          this.to,
                          this.period,
                          this.orientation,
                          this.users,
                          {
                            limit: 10,
                            direction: 'Desc'
                          },
                          emoji,
                      )

                      this.usersReactionStatistics.isModalOpen = true

                      this.usersReactionStatistics.getUsers()
                    }
                  }
                }
              }
            }
          }
      })
    }

    function prepareMetricChart(records: ReactionStatTotalRes['reactions'], field: ReactionStatRecordMetricsFields): EmojiChartMetricData {
      const colors = records.map(r => ColorMapper[r.mood])
      const data = records.map(r => r[field])
      const categories = records.map(({ mood, ...other }) => other)

      return {
        chart: {
          options: getChartOptions(colors, categories),
          series: [ {
            name: "",
            data: data
          } ]
        }
      }
    }

    return {
      reaction_count: prepareMetricChart(reactions, 'reaction_count'),
      user_count: prepareMetricChart(reactions, 'user_count')
    }
  }

  getEmojiPeriodChart(periods: ReactionStatTotalRes['periods'], ticks: FromToTicksReturnType): Record<ReactionStatRecordMetricsFields, EmojiChartMetricData['chart']> {
    const getMetricData = (field: ReactionStatRecordMetricsFields): EmojiChartMetricData['chart'] => {
      const metrics = Object.entries(periods).reduce<Record<ReactionMood, Record<string, number>>>((acc, [ date, emojiCategory ]) => {

        Object.keys(emojiCategory).forEach(category => {
          if (isEnumValue(ReactionMood, category)) {
            acc[category][date] = emojiCategory[ category ]?.[field]
          }
        })

        return acc
      }, {
        [ReactionMood.Positive]: {},
        [ReactionMood.None]: {},
        [ReactionMood.Negative]: {},
      })

      return {
        options: {
          chart: {
            type: 'bar',
            stacked: true,
            animations: {
              enabled: false
            },
            toolbar: {
              show: false
            },
              events: {
                click: (e: any, chart?: any, options?: any) => {
                  const { dataPointIndex, seriesIndex } = options
                  if (dataPointIndex !== -1 && seriesIndex !== -1) {
                    const neededTick = ticks.timeTicks[dataPointIndex]

                    if (neededTick && ticks) {
                      const from = neededTick
                      const to = ticks.timeTicks[dataPointIndex + 1] ?? this.to

                      this.usersReactionStatistics = new UsersReactionStatistics(
                          this.chat_id,
                          from,
                          to,
                          this.period,
                          this.orientation,
                          this.users,
                          {
                            limit: 10,
                            direction: 'Desc'
                          }
                      )

                      this.usersReactionStatistics.isModalOpen = true

                      this.usersReactionStatistics.getUsers()
                    }
                  }
                }
              }
          },
          plotOptions: {
            bar: {
              dataLabels: {
                total: {
                  enabled: true,
                  formatter(val?: string, opts?: any): string {
                    if (!val || +val === 0) {
                      return ''
                    }

                    return val
                  }
                },
              }
            }
          },
          grid: {
            padding: {
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
            },
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            colors: [
              'rgba(var(--a-primary), 1)',
              'rgba(var(--a-danger), 1)',
              'rgba(var(--a-success), 1)'
            ]
          },
          xaxis: {
            labels: {
              show: false
            },
            categories: convertLabels(ticks.timeTicks),
          }
        },
        series: [
          {
            name: i18n.t('reaction_category_none').toString(),
            data: Object.values(Object.assign({ ...ticks.mapTimeData }, metrics[ReactionMood.None]))
          },
          {
            name: i18n.t('reaction_category_negative').toString(),
            data: Object.values(Object.assign({ ...ticks.mapTimeData }, metrics[ReactionMood.Negative]))
          },
          {
            name: i18n.t('reaction_category_positive').toString(),
            data: Object.values(Object.assign({ ...ticks.mapTimeData }, metrics[ReactionMood.Positive]))
          },
        ]
      }
    }

    return {
      reaction_count: getMetricData("reaction_count"),
      user_count: getMetricData("user_count"),
    }
  }

  getTotal(ticks: FromToTicksReturnType) {
    this.isStatisticsLoading = true

    this.getReactionStatisticsByPeriod<ReactionStatModeEnum.Total>()
        .then(res => {
          if (res) {
            this.emojiCharts = this.getEmojiTotalCharts(res.reactions)
            this.periodChart = this.getEmojiPeriodChart(res.periods, ticks)
          }

          this.isStatisticsLoading = false
        })
  }
}
