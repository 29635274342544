export type ChatInfo = {
  chat_description: string | null
  chat_login: string
  chat_members: number
  chat_title: string
  description: string | null
  gentime: string
  total_avg_content_length: number
  total_messages: number
  user_ranks_by_reputation: boolean
  user_ranks_by_xp: boolean
}

export const enum StatisticPeriodEnum {
  Day = "Day",
  Week = "Week",
  Month = "Month",
  Year = "Year"
}

export type StatisticPeriodUserFilter = {
  values: Array<number | string>
  mode: StatisticPeriodUserFilterMode
}

export const enum StatisticPeriodUserFilterMode {
  Include = "Include",
  Exclude = "Exclude",
}
