import i18n from "@/i18n/i18n";
import { StatisticPeriodEnum } from "@/includes/types/statistics/statistics.types";

import moment, { DurationInputArg2 } from "moment";

export function updateDateToStart<T extends keyof typeof StatisticPeriodEnum>(dates: {
  from: string,
  to: string
}, period: T) {
  const MOMENT_FORMAT = 'YYYY-MM-DD'

  let { from, to } = dates

  let resFrom = ''

  if (period === "Week") {
    resFrom = moment(from).startOf('week').format(MOMENT_FORMAT)
  } else if (period === "Month") {
    resFrom = moment(from).startOf('month').format(MOMENT_FORMAT)
  } else if (period === "Year") {
    resFrom = moment(from).startOf('year').format(MOMENT_FORMAT)
  } else if (period === 'Day'){
    resFrom = moment(from).startOf('day').format(MOMENT_FORMAT)
  } else {
    resFrom = from
  }

  return { from: resFrom, to }
}

export function tickUnitByPeriod<T extends keyof typeof StatisticPeriodEnum>(period: T): DurationInputArg2 {
  switch (period) {
    case "Day":
      return 'day'
    case "Week":
    default:
      return 'week'
    case "Month":
      return 'month'
    case "Year":
      return 'year'
  }
}

export function convertLabels(labels: Array<string>): Array<string> {
  return labels.map((l: string, i: number, array: Array<string>) => {
    const start = moment(l).format('ll')
    const end = array[i + 1] ? moment(array[i + 1]).format('ll') : null

    let res = start

    if (end) {
      res = `${ start } - ${ end }`
    }

    return res
  })
}

export function getPeriodStatMetricTitle<T extends string>(metric: T, prefix = '') {
  return i18n.t(`period_metric_${ prefix }${ metric.toLowerCase() }_title`).toString()
}

export function getPeriodStatMetricHelpMessage<T extends string>(metric: T, prefix = '') {
  const key = `period_metric_${ prefix }${ metric.toLowerCase() }_help_message`

  return i18n.te(key) ? i18n.t(key).toString() : undefined
}

export async function getMaxValuesForEachMetric<T extends { data: Record<string, Record<string, number>> }>(items: Array<T>): Promise<Record<string, number>> {
  const result: Record<string, number> = {}

  items.forEach(item => {
    Object.keys(item.data).forEach(metric => {
      const m = metric

      if (!result[m]) {
        result[m] = 0;
      }

      result[m] = Math.max(
          ...Object.values(item.data[m] ?? []),
          result[m]
      );
    });
  });

  return result;
}

