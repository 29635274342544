import {
  ReactionStatOrientationEnum,
} from "@/includes/types/statistics/reactions";
import store from "@/store/store";
import GetUserInfoServiceImpl from "@/includes/logic/GetUserInfoServiceImpl";
import { tickUnitByPeriod, updateDateToStart } from "@/includes/utils/statistics";
import { TotalReactionStatistics } from "@/includes/logic/statistics/reactions/TotalReactionStatistics";
import { StatisticPeriodEnum, StatisticPeriodUserFilter } from "@/includes/types/statistics/statistics.types";

import { fromToTicks, FromToTicksReturnType } from "piramis-base-components/src/shared/utils/fromToTicks";

import moment from "moment";

export class ReactionStatistics {
  period: StatisticPeriodEnum = StatisticPeriodEnum.Week

  ticks: FromToTicksReturnType | null = null

  dates = {
    from: moment().add(-3, 'months').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD')
  }

  users: StatisticPeriodUserFilter | null = null

  orientation: ReactionStatOrientationEnum = ReactionStatOrientationEnum.Actor

  totalStat: TotalReactionStatistics | null = null

  getFromToTicks() {
    const { from, to } = updateDateToStart(this.dates, this.period)

    return fromToTicks(from, to, { unit: tickUnitByPeriod(this.period) })
  }

  getUsersInfo(ids?: Array<string>) {
    return new Promise((resolve) => {
      if (ids?.length) {
        const getUserInfoService = new GetUserInfoServiceImpl(store.getters.chatId)

        getUserInfoService.getUsersInfos(ids)
            .then(res => resolve(res ?? []))
      } else {
        resolve([])
      }
    })
  }

  getTotalStatistics() {
    this.ticks = this.getFromToTicks()

    this.totalStat = new TotalReactionStatistics(
        store.getters.chatId,
        this.dates.from,
        this.dates.to,
        this.period,
        this.orientation,
        this.users ? this.users : undefined,
    )

    this.totalStat.getTotal(this.ticks)
  }
}
