
































































import { UsersReactionStatistics } from "@/includes/logic/statistics/reactions/UsersReactionStatistics";
import { ReactionMood } from "@/includes/types/statistics/reactions";

import UserMainInfo from "piramis-base-components/src/components/UserMainInfo.vue";

import { Vue, Component, Prop } from 'vue-property-decorator';
import VueApexCharts from "vue-apexcharts";

@Component({
  components: {
    VueApexCharts,
    UserMainInfo
  },
  data() {
    return {
      ReactionCategory: ReactionMood
    }
  }
})
export default class UserReactionChartModal extends Vue {
  @Prop() userApi!: UsersReactionStatistics

  afterModalClose() {
    this.userApi.paginationChange(1, 10);
  }

  updatePagination(page: number, size: number) {
    this.userApi.paginationChange(page, size);

    this.userApi.getPartitionRequest()
  }
}
