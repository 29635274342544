var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"set-period w-full flex items-end gap-2"},[_c('date-picker-input',{staticClass:"w-full mt-0 mb-0 pb-0 pt-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.period,
        'key': 'from',
        'prefix': 'chat_stat_',
        'clearable': false,
        'disabled': _vm.disabled,
        'supportButtons': false
      }
    }}}),_c('date-picker-input',{staticClass:"w-full mt-0 mb-0 pb-0 pt-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.period,
        'key': 'to',
        'prefix': 'chat_stat_',
        'clearable': false,
        'disabled': _vm.disabled,
        'supportButtons': false
      },
    }}}),(_vm.searchButton)?_c('a-button',{staticClass:"flex-shrink-0",attrs:{"type":"primary","icon":"search","disabled":!_vm.isRightDate || _vm.disabled},on:{"click":_vm.searchPeriod}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }